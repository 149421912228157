import { render, staticRenderFns } from "./B744A.vue?vue&type=template&id=ed5634a0&scoped=true&"
import script from "./B744A.vue?vue&type=script&lang=js&"
export * from "./B744A.vue?vue&type=script&lang=js&"
import style0 from "./B744A.vue?vue&type=style&index=0&id=ed5634a0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed5634a0",
  null
  
)

export default component.exports